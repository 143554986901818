
export const BaseURL = "https://kanpainode-b83dbacd8a19.herokuapp.com/api";






//export const BaseURL = "http://localhost:82/api";

 