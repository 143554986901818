import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom'

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Alert,
  Snackbar,
  MenuItem,Select,InputLabel, FormControl,
     Box, List, ListItem, ListItemText,  Paper,CircularProgress, Backdrop
} from '@mui/material';
import EmailForm from '../../../src/components/EmailForm'; //  form de correo
import { APICall } from "api";
// third-party

import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
  HeaderSort,
  IndeterminateCheckbox,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';

import AddContact from 'sections/apps/contact/AddContact';
import ContactView from 'sections/apps/contact/ContactView';
import AlertContactDelete from 'sections/apps/contact/AlertContactDelete';

import makeData from 'data/react-table';
import { renderFilterTypes  } from 'utils/react-table';

// assets
import { Add, Danger, Edit, Element3, Eye,  Message, Trash, User, CloseCircle, Clock} from 'iconsax-react';



// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd, onEventCreated }) {
  const theme = useTheme();
  const [events, setEvents] = useState(data); 
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [products, setProducts] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
const [successMessage, setSuccessMessage] = useState(false);
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: 'fatherName', desc: false };

useEffect(() => {
      const fetchProducts = async () => {
        try {
          // Llamada para obtener los productos
          const response = await APICall.getProductsByInventoryFile();
          const fetchedProducts = response.data; 
          console.log("Productos",response.data);// Ajusta según cómo estructures los datos
    
          // Filtrar productos para que solo aparezca el primero si los nombres base son similares
          const filteredProducts = [];
          const seenNames = new Set();
    
          fetchedProducts.forEach(product => {
            // Extraer la parte base del nombre
            const baseName = product.name.split(" - ")[0].toLowerCase().trim();
    
            // Compara la parte base del nombre actual con los que ya hemos visto
            if (!seenNames.has(baseName)) {
              seenNames.add(baseName);
              filteredProducts.push(product);
            }
          });
    
          setProducts(filteredProducts); 
    

          const responseCat = await APICall.getProductCategories();
          setProductCategories(responseCat.data); 
          setIsLoading(false);
           
        } catch (error) {
          console.error("Error fetching products:", error);

        }
      };
    
      fetchProducts();
    }, []);



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize, expanded },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['avatar', 'email'], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns(['age', 'contact', 'visits', 'email', 'status', 'avatar']);
    } else {
      setHiddenColumns(['avatar', 'email']);
    }
    // eslint-disable-next-line
 
  }, [matchDownSM]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [openAddEventDialog, setOpenAddEventDialog] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({}); 
  const [productName, setProductName] = useState("Estrella Galicia")
  const [units, setUnits] = useState("190")
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const handleRowClick = (row) => {
    console.log("Linha clicada:", row);
    // Adicione a lógica que deseja executar ao clicar na linha
    // Exemplo: navegação para uma página de detalhes ou atualização de um estado
    navigate(`/event/${row.original._id}`);
  };


 const [newEvent, setNewEvent] = useState({
    name: "",
    eventDate: "",
    location: "",
    status: "activo",
    numberOfPeople: 25,
    products: [], 
  });
  const navigate = useNavigate();

  const handleAddProduct = () => {
    if (productName && units) {
      const newProduct = {
        id: Date.now(),
        name: productName,
        units: Number.parseInt(units, 10),
      }
      setProducts([...products, newProduct])
      setProductName("")
      setUnits("")
    }
  }

  const handleRemoveProduct = (productId) => {
    const previousQuantity = selectedProducts[productId] || 0;
    

    const updatedProducts = products.map((p) =>
      p._id === productId ? { ...p, stock: p.stock + previousQuantity } : p
    );
  
    setProducts(updatedProducts);
    setSelectedProducts((prev) => {
      const copy = { ...prev };
      delete copy[productId];
      return copy;
    });
  };

  useEffect(() => {
    console.log('Responsive adjustment:', matchDownSM);
  }, [matchDownSM]);

  useEffect(() => {
    setSelectedCount(Object.keys(selectedRowIds).length);
  }, [selectedRowIds]);



  const handleOpenAddEventDialog = () => {
  setOpenAddEventDialog(true);
};

const handleCloseAddEventDialog = () => {
  setOpenAddEventDialog(false);
  setOpenConfirmation(false);
};
const [errors, setErrors] = useState({}); 




const handleProductSelection = (productId, quantity) => {
  const numericQuantity = parseInt(quantity, 10) || 0;
  const product = products.find(p => p._id === productId);

  if (numericQuantity > product.stock) {
    setErrors({
      ...errors,
      [productId]: `You cannot select more than ${product.stock} units.`,
    });
    return;
  }

  // Aquí se actualiza correctamente el estado de `selectedProducts`
  setSelectedProducts((prevSelected) => ({
    ...prevSelected,
    [productId]: numericQuantity, 
  }));

  setErrors({ ...errors, [productId]: "" }); // Limpiar errores
};

const fetchProducts = async () => {
  try {
    const response = await APICall.getProductsByInventoryFile();
    const fetchedProducts = response.data;

    const filteredProducts = [];
    const seenNames = new Set();
    fetchedProducts.forEach(product => {
      const baseName = product.name.split(" - ")[0].toLowerCase().trim();
      if (!seenNames.has(baseName)) {
        seenNames.add(baseName);
        filteredProducts.push(product);
      }
    });

    setProducts(filteredProducts);
    return filteredProducts;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

const handleConfirmSaveEvent = async () => {
  if (!newEvent.name || !newEvent.eventDate || !newEvent.location || !newEvent.numberOfPeople) {
    alert("Por favor, completa todos los campos.");
    return;
  }
 
  setOpenAddEventDialog(false);
  setOpenConfirmation(true); 
};

const handleSaveEvent = async () => {
 
  if (!newEvent.name || !newEvent.eventDate || !newEvent.location || !newEvent.numberOfPeople) {
    alert("Por favor, completa todos los campos.");
    return;
  }
  setIsSaving(true); 
  console.log("Productos seleccionados:", selectedProducts);

  const selectedProductsArray = Object.keys(selectedProducts).map(productId => ({
    productId,
    quantity: selectedProducts[productId],
  }));

  const eventToSave = { ...newEvent, products: selectedProductsArray };

  try {
    const response = await APICall.addEvents(eventToSave);
    const createdEvent = response.data;
    handleAdd(createdEvent);

    let updatedProducts = [];

    for (const { productId, quantity } of selectedProductsArray) {
      const product = products.find(p => p._id === productId);
      if (!product) {
        console.warn(`Producto no encontrado en el estado: ${productId}`);
        continue;
      }

      const updatedProduct = { ...product, stock: product.stock - quantity };
      console.log(`Actualizando producto ${productId}:`, updatedProduct);

      try {
        const updateResponse = await APICall.editProductByUser(productId, updatedProduct);
        
        // Asegurar que la respuesta de la API contiene el producto actualizado
        if (updateResponse.data && updateResponse.data._id) {
          console.log(`Producto ${productId} actualizado correctamente:`, updateResponse.data);
          updatedProducts.push(updateResponse.data);
        } else {
          console.warn(`Producto ${productId} no retornó datos válidos de la API.`);
        }
      } catch (error) {
        console.error(`Error al actualizar el producto ${productId}:`, error);
      }
    }

    // Verificar qué productos realmente se actualizaron
    console.log("Productos actualizados en estado:", updatedProducts);

    // Actualizar el estado de los productos
    setProducts(prev =>
      prev.map(p => updatedProducts.find(up => up._id === p._id) || p)
    );

    // Obtener los productos actualizados desde la API y asegurarse de retornarlos
    const freshProducts = await fetchProducts();
    console.log("Productos después de actualizar:", freshProducts);

    setSuccessMessage(true);
    setNewEvent({ name: "", eventDate: "", location: "", status: "activo", numberOfPeople: 1, products: [] });
    setSelectedProducts({});
    handleCloseAddEventDialog();
    onEventCreated();
    setOpenConfirmation(false);
   {/*  window.location.reload();*/}
  } catch (error) {
    console.error("Error al guardar el evento:", error);
    alert("Ocurrió un error al actualizar el stock. Por favor, intenta de nuevo.");
  } finally {
    setIsSaving(false);
  }
};



  return (
    <>
      
      <TableRowSelection selected={selectedCount} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <Stack sx={{ fontWeight: '800', fontSize: '30px' }}>Event List</Stack>
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                      
          <Button
            variant="contained"
            startIcon={<Add />}
            color="success"
            onClick={handleOpenAddEventDialog}
            size="small"
          >
            Add new Event
          </Button>
          </Stack>
        </Stack>

        {isLoading ? (
  <Stack alignItems="center" sx={{ p: 3 }}>
    <CircularProgress />
    <Typography variant="h6" color="textSecondary">     
    Loading events...
    </Typography>
  </Stack>
) : data.length === 0 ? (
  <Typography variant="h6" color="textSecondary" sx={{ textAlign: 'center', marginTop: 2 }}>
    You have no events created. Please add a new event to get started.
  </Typography>
) : (
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
                sx={{ '& > th:first-of-type': { width: '58px' } }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    key={column.id}
                    {...column.getHeaderProps([{ className: column.className }])}
                  >
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();

              return (
                <Fragment key={i}>
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => {
                      //row.toggleRowSelected();
                      handleRowClick(row);
                    }}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: row.isSelected
                        ? alpha(theme.palette.primary.lighter, 0.35)
                        : 'inherit'
                    }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell
                        key={cell.id}
                        {...cell.getCellProps([{ className: cell.column.className }])}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded &&
                    renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                </Fragment>
              );
            })}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={gotoPage}
                  rows={rows}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                />

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
         )}
      </Stack>

      {/*  advertencia */}
<Dialog 
  open={openAddEventDialog}
  onClose={handleCloseAddEventDialog}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle sx={{fontSize:"25px", fontWeight:"800"}}>Add New Event</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Event Name"
      type="text"
      fullWidth
      value={newEvent.name}
      onChange={(e) => setNewEvent({ ...newEvent, name: e.target.value })}
    />
    <TextField
      margin="dense"
      label="Event Date"
      type="date"
      fullWidth
      InputLabelProps={{ shrink: true }}
      value={newEvent.eventDate}
      onChange={(e) => setNewEvent({ ...newEvent, eventDate: e.target.value })}
    />
    <TextField
      margin="dense"
      label="Location"
      type="text"
      fullWidth
      value={newEvent.location}
      onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
    />
     <FormControl fullWidth margin="dense">
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        labelId="status-label"
        id="status-select"
        value={newEvent.status}
        label="Estado"
        onChange={(e) => setNewEvent({ ...newEvent, status: e.target.value })}
      >
        <MenuItem value="activo">Active</MenuItem>
        <MenuItem value="inactivo">Inactive</MenuItem>
      </Select>
    </FormControl>
    <TextField
      margin="dense"
      label="Number of People"
      type="number"
      fullWidth
      value={newEvent.numberOfPeople}
      onChange={(e) => setNewEvent({ ...newEvent, numberOfPeople: e.target.value })}
    />
      
          <Box sx={{ maxWidth: 800,  padding: 2 }}>
      <Typography style={{fontSize:"22px", fontWeight:"700"}} variant="h6" gutterBottom>
        Detalles del producto
      </Typography>
      <FormControl fullWidth margin="dense">
  <InputLabel>Productos</InputLabel>
  <Select multiple value={Object.keys(selectedProducts)}>
    {products.map((product) => (
      <MenuItem key={product._id} value={product._id}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <span>{product.name} (Stock: {product.stock})</span>
          <TextField
            type="number"
            placeholder="Quantity"
            value={selectedProducts[product._id] || ""} // Asegurarse de que cada producto tenga su valor individual
            onChange={(e) => handleProductSelection(product._id, e.target.value)}
            style={{ marginLeft: "10px", width: "250px" }}
            InputProps={{ inputProps: { min: 0, max: product.stock } }}
            error={!!errors[product._id]}
            helperText={errors[product._id]} 
          />
        </div>
      </MenuItem>
    ))}
  </Select>
</FormControl>
      <Typography style={{fontWeight:"700", fontSize:"22px"}} variant="h6" gutterBottom>
        Resumen de productos
      </Typography>
      <Paper style={{ maxHeight: 300, overflow: "auto" }}>
      <List>
      {Object.keys(selectedProducts).length === 0 ? (
    <ListItem>
      <ListItemText primary="Agrega productos para tu evento!" style={{ color: "#888" }} />
    </ListItem>
  ) : (
    Object.entries(selectedProducts).map(([productId, units]) => {
      const product = products.find((p) => p._id === productId);
      return (
        <ListItem key={productId}>
          <ListItemText primary={`${units} unidades de ${product?.name}`} />
          <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(productId)}>
            <CloseCircle />
          </IconButton>
        </ListItem>
      );
    })
  )}
      </List>
    </Paper>
    </Box>
  </DialogContent>
  
  <DialogActions>
    <Button onClick={handleCloseAddEventDialog} color="primary">
      Cancel
    </Button>
    <Button
       onClick={handleConfirmSaveEvent}
      sx={{
        backgroundColor: "#F0531C",
        "&:hover": {
          backgroundColor: "#D94318", 
        },
      }}
      color="success"
      variant="contained"
    >
     
  Save Event
    </Button>
  </DialogActions>
</Dialog> {/* 
{isSaving && (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparente
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 9999, // Asegura que esté sobre todo
    }}
  >
    <CircularProgress size={60} sx={{ color: "#F0531C" }} />
  </Box>
)}
*/}
<Dialog open={openConfirmation} onClose={handleCloseAddEventDialog} maxWidth="sm" fullWidth>
  <DialogTitle>Event Creation Confirmation</DialogTitle>
  <DialogContent>
    <DialogContentText>
    Are you sure you want to create this event? 
    Once confirmed, the selected products will be deducted from stock and this action cannot be undone.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseAddEventDialog} color="primary" disabled={isSaving}>
      Cancel
    </Button>
    <Button onClick={handleSaveEvent} color="error" autoFocus disabled={isSaving}>
    {isSaving ? <CircularProgress size={24} sx={{ color: "#F0531C" }} /> : "Confirmar"}
    </Button>
  </DialogActions>
</Dialog>

<Snackbar
  open={successMessage}
  autoHideDuration={3000}
  onClose={() => setSuccessMessage(false)}
  anchorOrigin={{ vertical: "top", horizontal: "right" }}
>
  <Alert onClose={() => setSuccessMessage(false)} severity="success">
    ¡Evento agregado exitosamente!
  </Alert>
</Snackbar>

    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| CONTACT - LIST ||============================== //


const Events = () => {
  // Estado para almacenar los eventos
  const [events, setEvents] = useState([]);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);

  const [openEditSuccessPopup, setOpenEditSuccessPopup] = useState(false);

  const handleDeleteEvent = (_id) => {
    setEventIdToDelete(_id);
    setOpenConfirmation(true);
  };

  // Función para confirmar la eliminación
  const handleConfirmDelete = () => {
    if (eventIdToDelete) {
      APICall.deleteEvents(eventIdToDelete)
        .then(() => {
          console.log(`Evento con ID ${eventIdToDelete} eliminado exitosamente.`);
          setOpenSuccessPopup(true);
          refreshEvents();
        })
        .catch((error) => {
          console.error("Error al eliminar el evento:", error);
          setOpenErrorPopup(true);
        })
        .finally(() => {
          setOpenConfirmation(false);
          setEventIdToDelete(null);
        });
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmation(false);
    setEventIdToDelete(null);
  };

  const refreshEvents = () => {
    APICall.getEvents()
      .then((response) => {
        const formattedEvents = response.data.map((event) => ({
          ...event,
          eventDate: new Date(event.eventDate).toISOString().split("T")[0],
        }));
        setEvents(formattedEvents);
      })
      .catch((error) => {
        console.error("Error al obtener los eventos:", error);
      });
  };

  const handleEditEvent = (event) => {
    setEventToEdit({
      ...event,
      numberOfPeople: event.numberOfPeople || 0, // Evita valores `undefined`
    });
    setOpenEditPopup(true);
  };

  const handleSaveEdit = () => {
    if (!eventToEdit?.name || !eventToEdit?.eventDate || !eventToEdit?.location || !eventToEdit?.status || eventToEdit?.numberOfPeople === undefined) {
      alert("Por favor, completa todos los campos.");
      return;
    }

    const updatedEvent = {
      ...eventToEdit,
      numberOfPeople: Number(eventToEdit.numberOfPeople),
    };

    APICall.updateEvent(eventToEdit._id || eventToEdit.id, updatedEvent)
      .then(() => {
        console.log(`Evento con ID ${eventToEdit._id || eventToEdit.id} editado exitosamente.`);
        setOpenEditSuccessPopup(true);
        refreshEvents(); // Recarga eventos después de editar correctamente
      })
      .catch((error) => {
        console.error("Error al editar el evento:", error);
        setOpenErrorPopup(true);
      })
      .finally(() => {
        setOpenEditPopup(false);
        setEventToEdit(null);
      });
  };

  // Cargar eventos al montar el componente
  useEffect(() => {
    refreshEvents();
  }, []);

  // Función para renderizar la subfila (detalles del evento)
  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <div style={{ padding: "16px", width: "100%", backgroundColor: "#f9f9f9", border: "1px solid #ddd" }}>
        <h4>Detalles del Evento</h4>
        <p><strong>Nombre:</strong> {row.values.name}</p>
        <p><strong>Fecha:</strong> {row.values.eventDate}</p>
        <p><strong>Ubicación:</strong> {row.values.location}</p>
        <p><strong>Estado:</strong> {row.values.status}</p>
        <p><strong>Número de Personas:</strong> {row.values.numberOfPeople}</p>
      </div>
    );
  }, []);

  // Definir las columnas de la tabla
  const columns = useMemo(
    () => [
      {
        Header: "Events",
        accessor: "_id",
        disableSortBy: true,
        Cell: ({ value }) => <div style={{ display: "none" }}>{value}</div>,
      },
      {
        Header: "Nombre del Evento",
        accessor: "name",
      },
      {
        Header: "Fecha del Evento",
        accessor: "eventDate",
      },
      {
        Header: "Ubicación",
        accessor: "location",
      },
      {
        Header: "Estado",
        accessor: "status",
        Cell: ({ value }) => {
          return value === "activo" ? (
            <Chip label="Activo" color="success" />
          ) : (
            <Chip label="Inactivo" color="error" />
          );
        },
      },
      {
        Header: "Número de Personas",
        accessor: "numberOfPeople",
      },
      {
        Header: "Acciones",
        className: "cell-center",
        disableSortBy: true,
        Cell: ({ row }) => {
          const collapseIcon = row.isExpanded ? (
            <Add style={{ color: "#d32f2f", transform: "rotate(45deg)" }} />
          ) : (
            <Eye />
          );
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditEvent(row.original);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteEvent(row.values._id); // Pasar el ID del evento
                  }}
                >
                  <Trash />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ver evento">
                <IconButton
                  onClick={() => {
                    window.location.href = `/event/${row.values._id}`;
                  }}
                >
                  <Clock />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
        <Box sx={{ width: "100%", overflowX: "auto" }}>
        <ReactTable
          columns={columns}
          data={events}
          renderRowSubComponent={renderRowSubComponent}
          handleAdd={() => {}}
          onEventCreated={refreshEvents}
        />
      </Box>

      {/* Popup de confirmación */}
      <Dialog open={openConfirmation} onClose={handleCancelDelete} maxWidth="sm" fullWidth>
        <DialogTitle>Confirmación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar este evento? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditPopup} onClose={() => setOpenEditPopup(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: "20px", fontWeight: "700" }}>Editar Evento</DialogTitle>
        <DialogContent sx={{ justifyContent: "space-between" }}>
          <TextField
            sx={{ marginTop: "10px" }}
            fullWidth
            label="Nombre del evento"
            value={eventToEdit?.name || ""}
            onChange={(e) => setEventToEdit({ ...eventToEdit, name: e.target.value })}
          />
          <TextField
            sx={{ marginTop: "20px" }}
            fullWidth
            label="Fecha del Evento"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={eventToEdit?.eventDate || ""}
            onChange={(e) => setEventToEdit({ ...eventToEdit, eventDate: e.target.value })}
          />
          <TextField
            sx={{ marginTop: "20px" }}
            fullWidth
            label="Ubicación"
            value={eventToEdit?.location || ""}
            onChange={(e) => setEventToEdit({ ...eventToEdit, location: e.target.value })}
          />
          <TextField
            sx={{ marginTop: "20px" }}
            fullWidth
            label="Estado"
            select
            value={eventToEdit?.status || ""}
            onChange={(e) => setEventToEdit({ ...eventToEdit, status: e.target.value })}
          >
            <MenuItem value="activo">Activo</MenuItem>
            <MenuItem value="inactivo">Inactivo</MenuItem>
          </TextField>
          <TextField
            sx={{ marginTop: "20px" }}
            fullWidth
            label="Número de Personas"
            type="number"
            value={eventToEdit?.numberOfPeople || ""}
            onChange={(e) => setEventToEdit({ ...eventToEdit, numberOfPeople: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditPopup(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEdit} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup de éxito de edición */}
      <Dialog open={openEditSuccessPopup} onClose={() => setOpenEditSuccessPopup(false)} maxWidth="sm" fullWidth>
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            El evento se ha editado correctamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditSuccessPopup(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup de éxito */}
      <Dialog open={openSuccessPopup} onClose={() => setOpenSuccessPopup(false)} maxWidth="sm" fullWidth>
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            El evento se ha eliminado correctamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuccessPopup(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup de error */}
      <Dialog open={openErrorPopup} onClose={() => setOpenErrorPopup(false)} maxWidth="sm" fullWidth>
        <DialogTitle>¡Error!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ha ocurrido un error al realizar la acción. Por favor, inténtelo nuevamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorPopup(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Events;