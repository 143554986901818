// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  DocumentCode2,
  OceanProtocol,
  Level,
  ShieldCross,
  InfoCircle,
  I24Support,
  Driving,
  User,
  ArrowRight3,
  Shop,
  Home3,
  Calendar,
  Diagram,
  People,
  Card,
  Receipt1,
  Receipt,
  Box,
  Setting,
  MagicStar,
  Subtitle,
  Clock,
  Calculator
} from "iconsax-react";

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  profile: User,
  ArrowRight3: Home3,
  Shop: Shop,
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const BarOwner = {
  id: "BarOwner",
  title: (
    <FormattedMessage
      id="
Bar Owner Management"
    />
  ),
  type: "group",
  children: [
                {
                  id: 'dashboard',
                  title: <FormattedMessage id="Dashboard" />,
                  type: 'item',
                  url: '/dashboard',
                  icon: icons.ArrowRight3,
            
                },
    {
      id: "profile",
      title: <FormattedMessage id="Profile" />,
      type: "item",
      url: "/perfil/salesReport",
      icon: icons.profile,
    },
    {
      id: "perfilii",
      title: <FormattedMessage id="Analytics" />,
      type: "item",
      url: "/analytics",
      icon: Diagram,
    },
    {
      id: "perfill",
      title: <FormattedMessage id="Inventory" />,
      type: "item",
      url: "/inventory",
      icon: Box,
      disabled: false,
    },
                {
          id: 'perfil',
          title: <FormattedMessage id="Events" />,
          type: 'item',
          url: '/events',
          icon: Clock,
    
    
        },
        {
          id: 'perfile',
          title: <FormattedMessage id="Calculator" />,
          type: 'item',
          url: '/calculator',
          icon: Calculator,
    
    
        },
  ],
};

export default BarOwner;
