import { useEffect, useState } from 'react';
import { Stack, Box, Card, Typography } from '@mui/material';

import ProductTable, { ReactTable } from './productTable'
import EventRequest from './EventRequest';
import { APICall } from 'api';
import MainCard from 'components/MainCard';
import { IndeterminateCheckbox } from 'components/third-party/ReactTable';
import IconButton from 'themes/overrides/IconButton';

const EventById = () => {

  const [event, setEvent] = useState(false);
  const [products, setProducts] = useState([]);

  const pathArray = window.location.pathname.split('/');
  const eventId = pathArray[2]; // Assuming the ID is the third part of the path

  const columns = [
    {
      Header: "",
      accessor: "image",
      Cell: ({ row }) => (
<></>      ),
      disableSortBy: true,
    },
    {
      Header: "Product",
      accessor: "name",
      Cell: ({ row }) => {
        const { name, image } = row.values;
        return (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Box sx={{ width: 40, height: 40, overflow: "hidden" }}>
              <img
                src={APICall.getProductImage(image)}
                alt={name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </Box>
            <Typography variant="subtitle1">{name}</Typography>
          </Stack>
        );
      },
    },
    {
      Header: "Liquor Type",
      accessor: "category",
    },
    {
      Header: "Supplier",
      accessor: "details",
      Cell: ({ row }) => {
        const { details } = row.values;
        return (
          <Typography variant="body1">{"Carrefour"}</Typography>
        );
      }
    },
    {
      Header: "Real Consumption",
      accessor: (row) => row.stockWithoutConsumption || "Still not completed",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },


  ];

  useEffect(() => {
    APICall.getEventById(eventId)
      .then((response) => {
        console.log(response);
        setEvent(response.data); 
        setProducts(response.data.products.map((p) => ({
          category: p.productId.category,
          name: p.productId.name,
          details: p.productId.details,
          image: p.productId.image,
          stockWithoutConsumption: p.stockWithoutConsumption,
          "quantity": p.quantity
        })
        ))
        console.log("produuuctos",response);
      }

    )
      .catch((error) => {
        console.error("Error fetching event:", error);
      });
  }, [])
  return (
    <Card sx={{ p: 2 }}>
      <Stack spacing={3} >
        {/* Breadcrumbs (Dashboard / EventById History) */}
        <Box>
          <Typography variant="body2" color="text.secondary" fontWeight="medium">
            <span style={{ fontWeight: 'normal' }}>Event History / </span>
            <span style={{ fontWeight: 'bold', color: "black" }}>Event #12390234098</span>
          </Typography>
        </Box>

        <Box >
          {/* Table Section */}
          <Box sx={{ flex: 1 }}>
            <EventRequest eventParam={event} />
          </Box>
        </Box>

        {/* Table and Calendar Section in Flexbox */}
        <Box display="flex" justifyContent="space-between" background-color="#F4F6F8 !important" gap={3}>
          {/* Table Section */}
          <Box sx={{ flex: 1 }}>
            <MainCard>
              {event && <ReactTable
                columns={columns}
                data={products}
                renderRowSubComponent={() => <></>}
              />}
            </MainCard>
          </Box>
        </Box>
      </Stack>
    </Card>
  );
};

export default EventById;