
// project-imports
import { useCart } from '../../hooks/useCart';
import MainCard from "components/MainCard";
import LogoIcon from "components/logo/LogoIcon";
import arrow from "assets/images/arrow.png";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from "react";
import { Card, CardContent,useMediaQuery, Typography, Button, IconButton, TextField, Box, Grid, Stack, Slide, Pagination,Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  FormControl,
  InputLabel
 } from "@mui/material";
import { border, borderColor, grid } from "@mui/system";
import usePagination from "hooks/usePagination";
import { GlobalFilter } from "utils/react-table";
import { Edit2, Diagram, Trash, CloseCircle, TickCircle, Add, Pointer } from 'iconsax-react';
import { APICall } from 'api';
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import Papa from "papaparse";
import * as XLSX from "xlsx"; 




// ==============================|| SAMPLE PAGE ||============================== //

const Inventory = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { user, init } = useAuth();
  const [openSuccess, setOpenSuccess] = useState(false);

  const [globalFilter, setGlobalFilter] = useState("");
  const [add, setAdd] = useState(false);;
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]); 
  const [quantities, setQuantities] = useState({}); 
  const [productQuantities, setProductQuantities] = useState({});
  const [cart, setCart] = useState([]);
  const [open, setOpen] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [openInventory, setOpenInventory] = useState(false);
  const [openTable, setOpenTable]= useState(false);
  const [openSuccessInventory, setOpenSuccessInventory] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [productColumn, setProductColumn] = useState("");
  const [inventoryColumn, setInventoryColumn] = useState("");
  const [sheetNames, setSheetNames] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("");
  const [sheetsData, setSheetsData] = useState({}); // Store all sheets data here
  const [isCSV, setIsCSV] = useState(false); // Track if the file is CSV
  const [fileName, setFileName] = useState(""); // Store the uploaded filename
  const [soldColumn, setSoldColumn] = useState("");
  const [safetyStock, setSafetyStock] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
    const handleClickOpen = () => setOpen(true);
    const handleClickOpenInventory = () => setOpenInventory(true);

    const handleProductChange = (index, field, value) => {
      const updatedProducts = [...products];
      updatedProducts[index] = { ...updatedProducts[index], [field]: value };
      setProducts(updatedProducts);
    };
  const [addProducts, setAddProducts] = useState([
    { id: 1, name: "1906 Reserva especial", quantity: 112 },
    { id: 2, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 3, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 4, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 5, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 6, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 7, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 8, name: "Cerveza Mahou 12 unidades", quantity: 112 },
  ]);
  
  const itemsPerPage = 5;
  const outOfStockCount = products.filter(product => product.stock === 0).length;
  const bestSelling = products.filter(product => product.sold > 0);
  const lastSelling = products.filter(product => product.sold === 0);



useEffect(() => {
  const fetchProducts = async () => {
    try {
      // Llamada para obtener los productos
      const response = await APICall.getProductsByInventoryFile();
      const fetchedProducts = response.data; // Ajusta según cómo estructures los datos

      // Filtrar productos para que solo aparezca el primero si los nombres base son similares
      const filteredProducts = [];
      const seenNames = new Set();

      fetchedProducts.forEach(product => {
        // Extraer la parte base del nombre
        const baseName = product.name.split(" - ")[0].toLowerCase().trim();

        // Compara la parte base del nombre actual con los que ya hemos visto
        if (!seenNames.has(baseName)) {
          seenNames.add(baseName);
          filteredProducts.push(product);
        }
      });

      setProducts(filteredProducts); // Guardar los productos filtrados

      // Obtener categorías de productos
      const responseCat = await APICall.getProductCategories();
      setProductCategories(responseCat.data); // Ajusta según cómo estructures los datos
      
      setIsLoading(false); // Cambiar el estado de carga a false cuando todo esté listo
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false); // Si hay error, también debemos cambiar el estado de carga a false
    }
  };

  fetchProducts();
}, []);

  const handleClose = () => {
    setOpen(false);
    setOpenInventory(false);
    setOpenSuccess(false);
    setOpenSuccessInventory(false);
    setIsEditing(false); 
    setOpen(false);
    setOpenSuccess(false);
    setOpenResume(false);
    setEditingProductId(null);
    setProductToEdit(null); 
    setOpenUpload(false);
    setOpenTable(false);
  
  };


  const handleFileChange = (event) => {
    console.log(user)
    const file = event.target.files[0];
    if (!file) return;

    setFileName(file.name); // Store the filename

  
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      if (file.name.endsWith(".csv")) {
        setIsCSV(true); // File is CSV, hide sheet selector
        parseCSV(data);
      } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
        setIsCSV(false); // File is XLSX, show sheet selector
        parseXLSX(data);
      } else {
        alert("Invalid file format. Please upload a CSV or XLSX file.");
      }
    };
    reader.readAsBinaryString(file);
  };
  

  const parseCSV = (data) => {
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        setColumns(Object.keys(result.data[0] || []));
        setFileData(result.data);
        console.log(result.data);
      },
    });
  };

  const parseXLSX = (data) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const sheetNames = workbook.SheetNames;
    setSheetNames(sheetNames);
    setSelectedSheet(sheetNames[0]); // Default to the first sheet

    // Save all sheet data in state
    const allSheetsData = sheetNames.reduce((acc, sheetName) => {
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      acc[sheetName] = sheetData;
      return acc;
    }, {});

    setSheetsData(allSheetsData);

    // Set columns for the first sheet
    setColumns(Object.keys(allSheetsData[sheetNames[0]]?.[0] || []));
    setFileData(allSheetsData[sheetNames[0]]);
    console.log(allSheetsData);
  };

  const handleSheetChange = (event) => {
    const sheetName = event.target.value;
    setSelectedSheet(sheetName);

    // Get the columns and data for the selected sheet
    const selectedSheetData = sheetsData[sheetName];
    setColumns(Object.keys(selectedSheetData?.[0] || []));
    setFileData(selectedSheetData);
    console.log(selectedSheetData);
  };

  const processDataInventory = () => { 
    setOpenTable(false);
    if (!productColumn || !inventoryColumn) { // Asegúrate de que todas las columnas estén seleccionadas
      alert("Please select all required mappings.");
      return;
    }
  
    const aggregatedData = fileData.reduce((acc, row) => {
      const productName = row[productColumn];
      const stockValue = parseInt(row[inventoryColumn], 10);
       // Obtener // Parsear el valor de stock
  
      if (!acc[productName]) {
        acc[productName] = {
          product_name: productName,
          safety_stock: parseInt(safetyStock, 10),
          stock: stockValue || 0,
        };
      }
  
      return acc;
    }, {});
  
    const formattedData = Object.values(aggregatedData).map((item) => ({
      ...item,
      inventory_data: item.inventory_data.sort((a, b) => new Date(a.date) - new Date(b.date)),
    }));
  
    console.log("Mapped Data:", formattedData);
  
    const inventoryData = {
      products: formattedData,
      importedAt: new Date(),
      fileName,
    };
  
    APICall.addInventoryFile(inventoryData).then(() => {
      init();
    });
  
    setOpen(false);
    setOpenSuccessInventory(true);
  };

  
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  const handleQuantityChange = (id, newQuantity) => {
    setAddProducts((prevAddProducts) =>
      prevAddProducts.map((product) =>
        product.id === id ? { ...product, quantity: newQuantity } : product
      )
    );
  };
  const displayedProducts = addProducts.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  

    const PER_PAGE = 10;
   

    const handleAddProduct = (id) => {
      setAddedProducts((prev) => [...prev, id]); 
      console.log("Producto añadido con ID:", id); 
    };

    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(globalFilter.toLowerCase())
      );
      setFilteredProducts(filtered);
    }, [globalFilter, products]);

    const addToCart = (productId, quantity, name, details, price) => {
      console.log("Producto ID:", productId);  
      console.log("Cantidad:", quantity);
    
      if (quantity <= 0) {
        alert("La cantidad debe ser mayor a 0");
        return;
      }
    

      const productDetails = products.find((product) => product._id === productId);
      if (!productDetails) {
        console.error("Producto no encontrado");
        return; 
      }
    
      setCart((prevCart) => {
        console.log("Carrito anterior:", prevCart);  
    
        const existingProduct = prevCart.find((item) => item.productId === productId);
        console.log("Producto existente:", existingProduct); 
    
        if (existingProduct) {

          const updatedCart = prevCart.map((item) =>
            item.productId === productId
              ? { ...item, quantity: item.quantity + quantity } 
              : item
          );
          console.log("Carrito actualizado con producto existente:", updatedCart);  
          return updatedCart;
        }

        const newCart = [
          ...prevCart,
          {
            productId,
            quantity,
            name: productDetails.name,  
            details: productDetails.details ,
            price:productDetails.price
          }
        ];
        
        
        localStorage.setItem("cart", JSON.stringify(newCart));
        console.log("Carrito actualizado con nuevo producto:", newCart); 
        return newCart;
      });
      // Solo muestra el componente si hay productos 
  if (outOfStockCount === 0) {
    return null; // No renderiza nada
  }

      setProductQuantities((prev) => {
        const newQuantities = { ...prev, [name]: 0 }; 
        return newQuantities;
      });
    };
    const handleDecreaseQuantity = (productId) => {
      setProductQuantities((prev) => {
        const newQuantity = (prev[productId] || 0) > 0 ? prev[productId] - 1 : 0;
        return {
          ...prev,
          [productId]: newQuantity,
        };
      });
    };

    
    const handleViewDetails = () => {
      navigate(`/analytics`);
    };
    

    const handleDeleteProduct = async (productId) => {
      // Mostrar una ventana de confirmación
      const isConfirmed = window.confirm('¿Estás seguro de que deseas eliminar este producto?');
    
      // Si el usuario confirma, proceder con la eliminación
      if (isConfirmed) {
        console.log('Eliminar producto con ID:', productId);
        try {
          await APICall.deleteProduct(productId);
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product._id !== productId)
          );
        } catch (error) {
          console.error(`Error al eliminar el producto ${productId}:`, error);
        }
      } else {
        console.log('Eliminación cancelada por el usuario.');
      }
    };

    
  
    const [product, setProduct] = useState({
      name: '',
      details: '',
      price: 0,
      safetyStock: 0,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingProductId, setEditingProductId] = useState(null);

  
    const handleEditProduct = (id) => {
      console.log("Editando producto con ID:", id);
      setIsEditing(true); 
    };

    const handleEditSingleProduct = (id) => {
      console.log("Editando producto con ID:", id);
      const product = products.find((product) => product._id === id);
    
      if (product) {
        setEditingProductId(id); 
        setProductToEdit(product); 
      } else {
        console.error("Producto no encontrado");
      }
    };

    const handleSaveChanges = async () => {
      console.log("Guardando producto:", productToEdit); 
      try {
        // Llama a la API para editar el producto
        await APICall.editProduct(editingProductId, productToEdit);
    
        // Actualiza el estado local de los productos para reflejar los cambios
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product._id === editingProductId ? { ...product, ...productToEdit } : product
          )
        );
    
        // Salir del modo edición
        setEditingProductId(null);
        setProductToEdit(null);
    
        alert("Producto actualizado con éxito.");
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        alert("No se pudieron guardar los cambios.");
      }
    };

    const handleSafetyStock = (idx, value) => {
      let sanitizedValue = value.replace("%", "").trim();
  
      if (!isNaN(sanitizedValue) && sanitizedValue !== "") {
        sanitizedValue = Math.min(100, Math.max(0, Number(sanitizedValue))); // Restrict between 0 and 100
        setProducts((prevProducts) =>
          prevProducts.map((product, index) =>
            index === idx ? { ...product, safetyStock: sanitizedValue + "%" } : product
          )
        );
      } else {
        setProducts((prevProducts) =>
          prevProducts.map((product, index) =>
            index === idx ? { ...product, safetyStock: "" } : product
          )
        );
      }
    };
  
    const handleSave = () => {
      console.log("Producto guardado:", product);
      alert("Producto guardado correctamente");
      setIsEditing(false); 
    }
  
    const handleClickOpenResume = () => {
      setOpenResume(true);
      setOpen(false);
      setOpenUpload(false);
    };

    const handleClickOpenTable = () => {
      setOpenTable(true);
      setOpenInventory(false)
      setOpen(false);
      setOpenUpload(false);
    };
  
    const handleSucces = () => {
      setOpen(false);
      setOpenTable(false);
      setOpenResume(false);
      setOpenUpload(false);
      setOpenSuccess(true);
    };
  

    const count = Math.ceil(products.length / PER_PAGE);
    const _DATA = usePagination(products, PER_PAGE);

    const handleChangePage = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };
    const paginatedProducts = filteredProducts.slice((page - 1) * PER_PAGE, page * PER_PAGE);

    return (
   <div>
     <div>
     <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12}} style={{ padding: "16px", marginBottom:"70px" }}>
        <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px", marginBottom:"20px" }}>
             Your Inventory
         </Typography>
         <Typography variant="h6" sx={{ml: "16px", mb: "20px" }}>
           This is a general summary of your current inventory. Here you can see the total number of products, the items you have in stock, the best sellers and the least consumed. Use this view to maintain control of your inventory, manage on-hand quantities, and adjust prices to optimize your sales.
         </Typography>
         <Grid item xs={12} sm={6} md={3} >
             <Box  style={{ backgroundColor: "#313A13", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  }}>
                 <Typography variant="h4" mb={2} color={"#FFFFFF"}>Total Products</Typography>
                 <Box
                     sx={{
                        display: "flex",
                       justifyContent: "space-between",
                        alignItems: "center",
                         }}
                   >
                    <Typography variant="h4" color={"#FFFFFF"} fontWeight="bold">
                     {products.length}
                    </Typography>
                    {/*
                     <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "rgba(132, 192, 63, 1)" }}
                        >
                        {" "}
                        +10%{" "}
                  </Typography>
                  */}
               </Box>
             </Box>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
            <Box
              style={{
                backgroundColor: "#FBFAFE",
                borderColor: "#313A13",
                padding: "16px",
                borderRadius: "12px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="h4" mb={2} color={"rgba(17, 18, 23, 1)"}>
                Out of Stock
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4" fontWeight="bold">
                  {outOfStockCount}
                </Typography>
                {/*
                <Typography variant="h6" fontWeight="bold" sx={{ color: "red" }}>
                  +14%
                </Typography>
                */}
              </Box>
            </Box>
          </Grid>
         <Grid item xs={12} sm={6} md={3}>
             <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                 <Typography variant="h4" mb={2} color={"rgba(17, 18, 23, 1)"}>Best selling products</Typography>
                 
                 <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    alignItems: "center",
                   }}
                     >
                      <Typography variant="h4" fontWeight="bold">
                        {bestSelling.length}
                      </Typography>
                      {/*
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "rgba(132, 192, 63, 1)" }}
                        >
                        {" "}
                        +36% en ventas{" "}
                    </Typography>
                    */}
                   </Box>
             </Box>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
             <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                 <Typography variant="h4" mb={2} color={"rgba(17, 18, 23, 1)"}>Least selling products</Typography>
                 <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    alignItems: "center",
                   }}
                     >
                      <Typography variant="h4" fontWeight="bold">
                        {lastSelling.length}
                      </Typography>
                      {/*
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "rgba(132, 192, 63, 1)" }}
                        >
                        {" "}
                        +35% en ventas{" "}
                    </Typography>
                    */}
                   </Box>
             </Box>
         </Grid>
     </Grid>
        {isEditing && (
      <Grid>
          <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px", marginBottom:"20px" }}>
            Modo edicion
         </Typography>
         <Typography variant="h6" sx={{ml: "16px", mb: "20px" }}>
         Estás editando en el modo de edición general. En este modo activo, puedes modificar tanto las cantidades disponibles en tu inventario como el precio por unidad de cada producto.
         </Typography>
      </Grid>
         )}

            {editingProductId && (
      <Grid>
          <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px", marginBottom:"20px" }}>
            Editando un unico producto
         </Typography>
         <Typography variant="h6" sx={{ml: "16px", mb: "20px" }}>
         Add the products you offer to your catalogue here, including all relevant details such as type, brand, and packaging. If you need to make changes to your stock, you can do it directly in your inventories. This will help ensure your inventory is up-to-date and ready for orders.
         </Typography>
      </Grid>
         )}
         <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <TextField
          label="Search Products"
          variant="outlined"
          fullWidth
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          sx={{ marginBottom: 3, width: "20%", height:"20%", marginLeft:"20px" }}
        />
        
        <Box sx={{ display: "flex", gap: 2 }}>
          {/*
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontWeight:"600",
              backgroundColor: "rgba(87, 99, 232, 1)",
              textTransform: "none",
              borderRadius: "10px",
              padding: "8px 20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(67, 81, 191, 1)", 
              },
            }}
          >
            Optimize with AI
          </Button>
          */}
          {/*
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditProduct}
            sx={{
              backgroundColor: "rgba(240, 83, 28, 1)",
              textTransform: "none",
              borderRadius: "10px",
              padding: "8px 20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(217, 73, 14, 1)",
              },
            }}
          >
            Edit My Inventory
          </Button>
          */}
        </Box>
      </Box>
         
             <Grid container spacing={3} columns={10}>
             {!isEditing && (
                <Card 
                 onClick={handleClickOpen}
                  style={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'rgba(49, 58, 18, 1)', 
                    color: '#fff',
                    height: '350px',
                    width:"225px",
                    marginLeft:"20px",
                    marginTop:"40px",
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: "pointer"
                  }}
                >
                  <Add size={32}/>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: '700',
                      marginBottom: '8px',
                      textAlign: 'left',
                      fontSize:"26px",
                      width:"152px"
                    }}
                  >
                    Add a product to my catalog
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#D1D5DB',
                      textAlign: 'left',
                      marginBottom: '16px',
                      fontSize:"15px"
                    }}
                  >
                   Are you working with a product that is not in our database?
                  </Typography>

                </Card>
              )}
             {isLoading ? (
              <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px", marginLeft:"450px" }} item xs={12} sm={6} md={3}>
                <Box >
                  <CircularProgress />
                </Box>
              </Grid>
              ) :paginatedProducts.length > 0 ? (
               paginatedProducts.map((product, index) => {
                 
                 const price = parseFloat(product.price);
                 const sellPrice = parseFloat(product.sellPrice); 
           
                 let discountPercentage = null;
                   if (!isNaN(price) && price > 0 && product.sellPrice > 0 && price > sellPrice) {
                     discountPercentage = ((price - product.sellPrice) / price) * 100;
                   }
 
                   
                   const formattedDiscount = discountPercentage !== null ? `${Math.round(discountPercentage)}%` : null;
 
                   
                   const showDiscount = price > sellPrice;
           
                 return (
                   <Slide key={index} direction="up" in={true} timeout={50}>
                     <Grid item xs={10} sm={5} lg={2}>
                        <div style={{marginTop:"20px"}}>
                          {isEditing && (
                            <Card className="w-[400px] max-w-[95vw] relative">
                              {/* Botón de cierre e indicador de estado */}
                              <div
                                className="flex items-center justify-between px-4 pt-4"
                                style={{ alignItems: "center", display:"flex", justifyContent:"space-between", alignContent:"center" }}
                              >
                                {/* Indicador de estado */}
                                <div className="flex items-center gap-2" style={{marginTop:"10px", marginLeft:"10px"}}>
                                  <TickCircle size={"15px"} color="rgba(240, 83, 28, 1)" className="h-5 w-5" style={{marginRight:"5px"}} />
                                  <span className="text-orange-500">importado</span>
                                </div>

                                {/* Botón de cierre */}
                                <button
                                  onClick={handleClose}
                                  style={{
                                    marginTop:"10px",
                                    border: "none",
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                  className="text-gray-500 hover:text-gray-700"
                                >
                                  <CloseCircle size={"20px"} className="h-5 w-5" />
                                </button>
                              </div>

                              <CardContent className="pt-6">
                                <div className="space-y-6">
                                  {/* Información general del producto */}
                                  <div>
                                    <Typography variant="h5" className="text-2xl font-bold">
                                      {product.name}
                                    </Typography>
                                    <Typography
                                      style={{ color: "rgba(108, 117, 147, 1)", marginBottom: "10px" }}
                                      className="text-gray-500"
                                    >
                                      {product.details.length > 100 ? product.details.substring(0, 100) + '...' : product.details}
                                    </Typography>
                                  </div>

                                  {/* Campos editables */}
                                  <div className="space-y-4">
                                      {/* Stock */}
                                      <div>
                                        <TextField
                                          id="stock"
                                          type="number"
                                          value={product.stock === 0 ? 0 : product.stock || ""}
                                          disabled // Esto deshabilita el campo
                                          className="text-xl font-semibold"
                                          fullWidth
                                          InputProps={{
                                            style: {
                                              color: "rgba(108, 117, 147, 1)", // Cambia el color del texto a gris
                                              backgroundColor: "#f5f5f5", // Fondo gris claro para indicar que está deshabilitado
                                            },
                                          }}
                                        />
                                        <Typography
                                          style={{
                                            color: "rgba(108, 117, 147, 1)",
                                            fontSize: "12px",
                                            marginBottom: "4px",
                                          }}
                                        >
                                          Unidades en inventario
                                        </Typography>
                                      </div>

                                      {/* Stock de seguridad 
                                      <div>
                                        <TextField
                                          type="number"
                                          value={product.safetyStock || ""}
                                          onChange={(e) => setProduct({
                                            ...product,
                                            safetyStock: parseInt(e.target.value, 10),
                                          })}
                                          fullWidth
                                        />
                                        <Typography
                                          style={{ color: "rgba(108, 117, 147, 1)", fontSize: "12px" }}
                                          variant="subtitle2"
                                          className="text-gray-500"
                                        >
                                          Stock de seguridad
                                        </Typography>
                                      </div>
                                      */}
                                    </div>

                                  {/* Botón de guardar
                                  <Button
                                    style={{
                                      marginTop: "6px",
                                      backgroundColor: "rgba(240, 83, 28, 1)",
                                      color: "rgba(253, 252, 255, 1)",
                                      width: "150px",
                                    }}
                                    className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                                    onClick={() => handleSaveChanges()}
                                  >
                                    Guardar
                                  </Button>
                                   */}
                                </div>
                              </CardContent>
                            </Card>
                          )}
                        </div>
                        {editingProductId === product._id && (
                          <div className="absolute top-0 left-0 w-full h-full bg-white z-10">
                            <Card className="w-[400px] max-w-[95vw] relative">
                              {/* Botón de cierre e indicador de estado */}
                              <div
                                className="flex items-center justify-between px-4 pt-4"
                                style={{ alignItems: "center", display:"flex", justifyContent:"space-between", alignContent:"center" }}
                              >
                                {/* Indicador de estado */}
                                <div className="flex items-center gap-2" style={{marginTop:"10px", marginLeft:"10px"}}>
                                  <TickCircle size={"15px"} color="rgba(240, 83, 28, 1)" className="h-5 w-5" style={{marginRight:"5px"}} />
                                  <span className="text-orange-500">importado</span>
                                </div>

                                {/* Botón de cierre */}
                                <button
                                  onClick={handleClose}
                                  style={{
                                    marginTop:"10px",
                                    border: "none",
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                  className="text-gray-500 hover:text-gray-700"
                                >
                                  <CloseCircle size={"20px"} className="h-5 w-5" />
                                </button>
                              </div>

                              <CardContent className="pt-6">
                                <div className="space-y-6">
                                  {/* Información general del producto */}
                                  <div>
                                    <Typography variant="h5" className="text-2xl font-bold">
                                      {product.name}
                                    </Typography>
                                    <Typography
                                      style={{ color: "rgba(108, 117, 147, 1)", marginBottom: "10px" }}
                                      className="text-gray-500"
                                    >
                                      {product.details.length > 100 ? product.details.substring(0, 100) + '...' : product.details}
                                    </Typography>
                                  </div>

                                  {/* Campos editables */}
                                  <div className="space-y-4">
                                    {/* Precio */}
                                    <div>
                                    <TextField
                                        id="stock"
                                        type="number"
                                        value={productToEdit.stock || ""}  // Asegúrate de que `productToEdit` esté siendo usado y contiene el valor correcto
                                        onChange={(e) => {
                                          const updatedProduct = {
                                            ...productToEdit,
                                            stock: parseInt(e.target.value, 10),
                                          };
                                          console.log(updatedProduct); // Verifica si el valor de `stock` se actualiza correctamente
                                          setProductToEdit(updatedProduct);
                                        }}
                                        className="text-xl font-semibold"
                                        fullWidth
                                      />
                                        <Typography
                                          style={{
                                            color: "rgba(108, 117, 147, 1)",
                                            fontSize: "12px",
                                            marginBottom: "4px",
                                          }}
                                        >
                                          Unidades en inventario
                                        </Typography>
                                      </div>

                                    {/* Stock de seguridad 
                                    <div>
                                      <TextField
                                        type="number"
                                        value={product.safetyStock || ""}
                                        onChange={(e) =>
                                          setProduct({
                                            ...product,
                                            safetyStock: parseInt(e.target.value, 10),
                                          })
                                        }
                                        fullWidth
                                      />
                                      <Typography
                                        style={{ color: "rgba(108, 117, 147, 1)", fontSize: "12px" }}
                                        variant="subtitle2"
                                        className="text-gray-500"
                                      >
                                        Stock de seguridad
                                      </Typography>
                                    </div>
                                    */}
                                  </div>

                                  {/* Botón de guardar */}
                                  <Button
                                    style={{
                                      marginTop: "6px",
                                      backgroundColor: "rgba(240, 83, 28, 1)",
                                      color: "rgba(253, 252, 255, 1)",
                                      width: "150px",
                                    }}
                                    className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                                    onClick={handleSaveChanges}
                                  >
                                    Guardar
                                  </Button>
                                </div>
                              </CardContent>
                            </Card>
                            </div>
                          )}
                        {!isEditing && editingProductId !== product._id && (

                       <Card
                       style={{
                         padding: '16px',
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'space-between',
                         alignItems: 'center',
                         borderRadius: '12px',
                         boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                         backgroundColor: product.stock === 0 ? '#f5f5f5' : '#fff', // Fondo gris si el stock es 0
                         height: '100%',
                         position: 'relative',
                         overflow: 'hidden',

                         opacity: product.stock === 0 ? 0.6 : 1, 
                         zIndex:9
                       }}
                     >
                       {/* Contenido de la tarjeta */}
                       <div
                         style={{
                           zIndex:10,
                           position: 'absolute',
                           top: '8px',
                           right: '8px',
                           display: 'flex',
                           gap: '8px',
                         }}
                       >
                         <IconButton
                           aria-label="view details"
                           size="small"
                           onClick={() => handleViewDetails(product._id)}
                           disabled={product.stock === 0} // Deshabilita el botón si el stock es 0
                         >
                           <Diagram size="20" color="#1c1c1c" />
                         </IconButton>
                         <IconButton
                           aria-label="delete"
                           size="small"
                           onClick={() => handleDeleteProduct(product._id)} // Botón de eliminar sigue habilitado
                         >
                           <Trash size="20" color="#1c1c1c" />
                         </IconButton>
                       </div>
                     
                       <img
                         src={APICall.getProductImage(product.image) || "/images/inventoryImagenes/logodark.png"}
                         alt={product.name}
                         style={{
                           width: '100px',
                           height: '100px',
                           objectFit: 'contain',
                           marginBottom: '8px',
                           marginTop: '35px',
                         }}
                       />
                     
                       <div
                         style={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'flex-start',
                           width: '100%',
                           borderBottom: '1px solid #b1b4b9',
                           paddingBottom: '4px',
                         }}
                       >
                         <div style={{ display: 'flex', alignItems: 'baseline' }}>
                           <Typography
                             variant="h5"
                             color="textPrimary"
                             style={{
                               fontWeight: '400',
                               fontSize: '14px',
                               color: 'rgba(108, 117, 147, 1)',
                               marginRight: '4px',
                             }}
                           >
                             Stock:
                           </Typography>
                           <Typography
                             variant="h5"
                             color="textPrimary"
                             style={{
                               fontWeight: '700',
                               fontSize: '14px',
                               marginLeft:"10px",
                               color: product.stock === 0 ? 'red' : 'inherit', // Color rojo si el stock es 0
                             }}
                           >
                             {product.stock === 0 ? 
                                'Out of stock' : `${product.stock} uds.`}
                           </Typography>
                         </div>
                       </div>
                     
                       <div
                         style={{
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'flex-start',
                           width: '100%',
                           marginTop: '5px',
                         }}
                       >
                         <Typography
                           variant="subtitle1"
                           style={{
                             fontSize: '15px',
                             fontWeight: '700',
                             textAlign: 'left',
                           }}
                         >
                           {product.name}
                         </Typography>
                         <Typography
                           variant="body2"
                           color="textSecondary"
                           style={{
                             color: 'rgba(108, 117, 147, 1)',
                             textAlign: 'left',
                             fontSize: '0.70rem',
                           }}
                         >
                           {product.details.length > 100 ? product.details.substring(0, 100) + '...' : product.details}
                         </Typography>
                       </div>
                     </Card>
                        )}
                     </Grid>
                   </Slide>    
                 );
               })
             ) : (

               <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
                  You must upload your inventory data to proceed with inventory management.
                </Typography>
                <Box textAlign="center" mt={2}>
                  <Box>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      href="perfil/salesReport"
                    >
                      Upload Inventory Data
                    </Button>
                  </Box>
                </Box>
                 
               </Typography>
               
             )}
             
           </Grid>
           {/* Pop up Añadir producto*/}
           <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                sx={{
                  color: "rgba(22, 23, 29, 1)",
                  fontWeight: "700",
                }}
              >
               Add new product(s)
              </DialogTitle>
              <Typography
                variant="body2"
                sx={{ marginTop: 0, marginLeft: 3, marginRight: 3 ,color: "rgba(108, 117, 147, 1)", width:"350px" }}
              >
                Need to add a product to your inventory? No problem, add it.
              </Typography>
              <Divider sx={{mt:2,mb:2}}/>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  sx={{
                    width:"320px",
                    border: "1px solid black",
                    backgroundColor: "#fff",
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                    mb:"10px"
                  }}
                  onClick={handleClickOpenInventory}
                >
                 Import product(s) from file
                </Button>
              </DialogActions>
              {/*
              <DialogActions sx={{ justifyContent: "center", mb:2 }}>
                <Button
                  
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  Agregar nuevo producto desde base de datos
                </Button>
              </DialogActions>
              */}
            </Dialog>
            {/* Pop up Upload producto*/}
                  <Dialog open={openUpload} onClose={handleClose}>
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Please upload your file
                    </DialogTitle>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 3, color: "rgba(108, 117, 147, 1)" }}
                    >
                      We accept CSV, XSL/XLSX, ODS, TSV, JSON, or PDF formats.
                    </Typography>
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Import your database
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        type="file"
                        fullWidth
                        variant="outlined"
                      />
                    </DialogContent>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 3, color: "rgba(108, 117, 147, 1)" }}
                    >
                      We accept CSV, XSL/XLSX, ODS, TSV, JSON, or PDF formats.
                    </Typography>
                    <DialogActions sx={{justifyContent:"center",mb:2, mt:2}}>
                      <Button sx={{backgroundColor:"rgba(253, 237, 231, 1)",textTransform: "none",}} onClick={handleClose}>Skip this step</Button>
                      <Button sx={{width:"120px"}} onClick={handleClickOpenResume} color="primary" variant="contained">
                        Next
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* Pop up Resumen de pedido*/}
                  <Dialog open={openResume} onClose={handleClose} >
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Resumen de productos
                    </DialogTitle>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 4, color: "rgba(108, 117, 147, 1)", width:"400px" }}
                    >
                      Aquí encontrarás un listado de los productos que vas a añadir. Puedes
                      editar las cantidades antes de confirmar los cambios.
                    </Typography>
                    <DialogContent sx={{justifyContent:"center"}}>
                      <Box >
                        {displayedProducts.map((product) => (
                          <Stack
                            justifyContent="center"
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{
                              
                              padding: "8px 0",
                              borderBottom: "1px solid #f0f0f0",
                            }}
                            key={product.id}
                          >
                            <TextField
                              type="number"
                              value={product.quantity}
                              onChange={(e) =>
                                handleQuantityChange(product.id, parseInt(e.target.value, 10))
                              }
                              sx={{ width: "80px", ml:2 }}
                            >
                              </TextField>
                              <Typography variant="body2" sx={{color:"rgba(108, 117, 147, 1)"}}>
                              unidades
                            </Typography>

                            <Typography variant="body2" sx={{color:"rgba(108, 117, 147, 1)", fontWeight:"600", fontSize:"15px"}}>
                              {product.name}
                            </Typography>
                          </Stack>
                        ))}
                      </Box>
                      <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                        <Pagination
                          count={Math.ceil(addProducts.length / itemsPerPage)}
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Stack>
                    </DialogContent>
                    <Divider sx={{ mb: 2 }} />
                    <DialogTitle
                      onClick={handleClose}
                      sx={{
                        color: "rgba(240, 83, 28, 1)",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      + Agregar otro producto
                    </DialogTitle>
                    <DialogActions sx={{ justifyContent: "center", mb: 2, mr: 2, ml: 2 }}>
                      <Button
                        sx={{
                          width: "160px",
                          border: "1px solid black",
                          backgroundColor: "#fff",
                          color: "black",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                      <Button
                        sx={{ width: "160px" }}
                        onClick={handleSucces}
                        color="primary"
                        variant="contained"
                      >
                        Confirmar
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={openInventory} onClose={handleClose}>
                    <DialogTitle sx={{ fontWeight: "700", fontSize: "20px" }}>
                      Please upload your inventory file
                    </DialogTitle>
                    <Typography sx={{ marginLeft: 3, color: "rgba(108, 117, 147, 1)" }}>
                      We accept CSV, XLS/XLSX.
                    </Typography>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="file"
                        type="file"
                        fullWidth
                        variant="outlined"
                        inputProps={{ accept: ".csv, .xlsx, .xls" }}
                        onChange={handleFileChange}
                      />
                      {columns.length > 0 && (
                        <Box mt={3}>
                            { !isCSV && (
                              <>
                            <Typography sx={{ mb: 1 }}>Select Sheet:</Typography>
                          <FormControl fullWidth margin="dense">
                            <InputLabel>Sheet</InputLabel>
                            <Select
                              value={selectedSheet}
                              onChange={handleSheetChange}
                            >
                              {sheetNames.map((sheet) => (
                                <MenuItem key={sheet} value={sheet}>
                                  {sheet}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>   </> )}

                          <Typography sx={{ mb: 1 }}>Map Columns:</Typography>
                          <FormControl fullWidth margin="dense">
                            <InputLabel>Product Name</InputLabel>
                            <Select
                              value={productColumn}
                              onChange={(e) => setProductColumn(e.target.value)}
                            >
                              {columns.map((col) => (
                                <MenuItem key={col} value={col}>
                                  {col}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          
                          <FormControl fullWidth margin="dense">
                          <InputLabel>Stock</InputLabel>
                          <Select
                            value={inventoryColumn}
                            onChange={(e) => setInventoryColumn(e.target.value)}
                          >
                            {columns.map((col) => (
                              <MenuItem key={col} value={col}>
                                {col}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        
                    

                        </Box>
                      )}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                      <Button
                        sx={{ width: "200px", mb: 2 }}
                        onClick={handleClickOpenTable}
                        color="primary"
                        variant="contained"
                        disabled={!productColumn }
                      >
                        Next
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog open={openSuccessInventory} onClose={handleClose}>
                    <DialogTitle sx={{ fontWeight: "700" }}>Success!</DialogTitle>
                    <Typography sx={{ margin: 3, color: "rgba(108, 117, 147, 1)" }}>
                      The inventory has been successfully added!
                    </Typography>
                    <Divider />
                    <DialogActions sx={{ justifyContent: "center" }}>
                      <Button onClick={handleClose} color="primary" variant="contained">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog> 
                  <Dialog open={openTable} onClose={handleClose}>
                    <DialogTitle sx={{ fontWeight: "700", fontSize: "20px" }}> 
                      Product Overview
                    </DialogTitle>
                    <Typography sx={{ marginLeft: 3, color: "rgba(108, 117, 147, 1)" }}>
                      Here you will find a list of the products you are going to add. 
                      You can edit the quantities before confirming the changes.
                    </Typography>
                    <DialogContent>
                      {columns.length > 0 && (
                        <Box mt={3}>
                          {!isCSV && (
                            <>
                              <Typography sx={{ mb: 1 }}>Select Sheet:</Typography>
                              <FormControl fullWidth margin="dense">
                                <InputLabel>Sheet</InputLabel>
                                <Select value={selectedSheet} onChange={handleSheetChange}>
                                  {sheetNames.map((sheet) => (
                                    <MenuItem key={sheet} value={sheet}>
                                      {sheet}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </>
                          )}



                          {/* Tabla de productos */}
                          <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/* <TableCell sx={{ fontWeight: "bold" }}>Image</TableCell> */}
                              <TableCell sx={{ fontWeight: "bold" }}>Product Name</TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>Safety Stock (%)</TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>Supplier</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {products.map((product, idx) => (
                              <TableRow key={product.id}>
                                
                                <TableCell>{product.name}</TableCell>
                                {/* Safety Stock Input */}
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={product.safetyStock ?? 10}
                                    onChange={(e) => handleSafetyStock(idx, e.target.value)}
                                    placeholder="Enter a percentage"
                                  />
                                </TableCell>
                                {/* Supplier Input */}
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={product.supplier || ""}
                                    onChange={(e) => handleProductChange(idx, "supplier", e.target.value)}
                                    placeholder="Enter supplier"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                        </Box>
                      )}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                      <Button
                        sx={{ width: "200px", mb: 2 }}
                        onClick={processDataInventory}
                        color="primary"
                        variant="contained"
                        disabled={!productColumn}
                      >
                        Next
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* Paginación */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                    <Pagination
                      count={Math.ceil(products.length / PER_PAGE)}
                      page={page}
                      onChange={handleChangePage}
                      color="primary"
                    />
                  </Box>
     </div>
   </div>
   )
 }

export default Inventory;
