import React, { useState, useEffect } from "react";
import {
    Box,
    Container,
    Grid,
    Typography,
    TextField,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Tab,
    Tabs,
    CircularProgress,
    Card,
    CardContent,
    InputAdornment,
    FormControl,
} from "@mui/material";
import {
    Add,
    Minus,
    
    Trash,
    Money,
    Note1,
    Chart21,
    DollarCircle,
    Receipt21
  } from 'iconsax-react';

import { ArrowRight3, More } from "iconsax-react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactApexChart from "react-apexcharts"; 
import widgets from "../extra-pages/data.json";
import { useNavigate } from "react-router-dom";
import { APICall } from 'api';
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";

const Calculator = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [ingredients, setIngredients] = useState([{
        name: "",
        purchasePrice: 0,
        quantityPurchased: 0,
        quantityPurchasedUnit: "UNIT",
        quantityUsed: 0,
        quantityUsedUnit: "UNIT",
        ingredientCost: 0,
    }]);
    const productLimit = 5; 
    const { user, init } = useAuth();
    const [menuItemName, setMenuItemName] = useState('');
    const [menuPrice, setMenuPrice] = useState(0);
    const [targetFoodCost, setTargetFoodCost] = useState(0);
    const [comments, setComments] = useState('');
    const [products, setProducts] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [predictDays, setPredictDays] = useState(3); 
    const [page, setPage] = useState(1);
    const [categoryFilter, setCategoryFilter] = useState("all");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProductEstimate, setSelectedProductEstimate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [actualFoodCost, setActualFoodCost] = useState(0);
    const [expectedProfit, setExpectedProfit] = useState(0);
    const [expectedSellingPrice, setExpectedSellingPrice] = useState(0);

    const startIndex = (page - 1) * productLimit;
    const endIndex = startIndex + productLimit;
    const navigate = useNavigate();

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const filteredProducts = products.filter((product) => {
        const matchesCategory = categoryFilter === "all" || product.category === categoryFilter;
        const matchesSearch = product.name.toLowerCase().includes(globalFilter.toLowerCase());
        return matchesCategory && matchesSearch;
    });

    const updateIngredient = (index, field, value) => {
        const updatedIngredients = [...ingredients];
        updatedIngredients[index][field] = value;
        setIngredients(updatedIngredients);
    };



    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);

            try {
                const response = await APICall.getProductsByUserReport();
                const fetchedProducts = response.data;

                const filteredProducts = [];
                const seenNames = new Set();

                fetchedProducts.forEach(product => {
                    const baseName = product.name.split(" - ")[0].toLowerCase().trim();
                    if (!seenNames.has(baseName)) {
                        seenNames.add(baseName);
                        filteredProducts.push(product);
                    }
                });

                setProducts(filteredProducts);

                const responseCat = await APICall.getProductCategories();
                setProductCategories(responseCat.data);
            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const widget = widgets?.value?.accountBalance;

    if (!widget) {
        return null;
    }

    // Función para calcular el costo del ingrediente
    const calculateIngredientCost = (ingredient) => {
        return ingredient.purchasePrice * (ingredient.quantityUsed / ingredient.quantityPurchased);
    };

    // Función para agregar un nuevo ingrediente
    const addIngredient = () => {
        setIngredients([...ingredients, {
            name: "",
            purchasePrice: 0,
            quantityPurchased: 0,
            quantityPurchasedUnit: "UNIT",
            quantityUsed: 0,
            quantityUsedUnit: "UNIT",
            ingredientCost: 0,
        }]);
    };

    // Función para calcular los resultados
    const calculateResults = () => {
        let totalIngredientCost = ingredients.reduce((total, ingredient) => total + calculateIngredientCost(ingredient), 0);
        const actualFoodCost = (totalIngredientCost / menuPrice) * 100;
        const expectedProfit = menuPrice - totalIngredientCost;
        const expectedSellingPrice = menuPrice;

        setActualFoodCost(actualFoodCost);
        setExpectedProfit(expectedProfit);
        setExpectedSellingPrice(expectedSellingPrice);
    };

    // Función para limpiar todos los campos
    const clearAll = () => {
        setMenuItemName('');
        setMenuPrice(0);
        setTargetFoodCost(0);
        setComments('');
        setIngredients([{
            name: "",
            purchasePrice: 0,
            quantityPurchased: 0,
            quantityPurchasedUnit: "UNIT",
            quantityUsed: 0,
            quantityUsedUnit: "UNIT",
            ingredientCost: 0,
        }]);
    };

    return (
<Container maxWidth="xl" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <Receipt21 size={32} variant="Bulk" color="#F0531C"/>
          <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
            Food Cost Calculator
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary">
          Calculate your recipe costs and optimize your menu pricing
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Columna izquierda */}
        <Grid item xs={12} md={8}>
          {/* Recipe Details */}
          <Paper elevation={0} sx={{ p: 3, mb: 4, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              <Note1 size={24} variant="Bulk" color="#F0531C"/>
              <Typography variant="h6" color="primary">
                Recipe Details
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Menu Item Name"
                  placeholder="Eg: Alfredo Pasta"
                  value={menuItemName}
                  onChange={(e) => setMenuItemName(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Menu Price"
                  type="number"
                  value={menuPrice}
                  onChange={(e) => setMenuPrice(Number(e.target.value))}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setMenuPrice(Math.max(0, menuPrice - 1))}>
                          <Minus size={16} variant="Linear"/>
                        </IconButton>
                        <IconButton size="small" onClick={() => setMenuPrice(menuPrice + 1)}>
                          <Add size={16} variant="Linear"/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Target Food Cost"
                  type="number"
                  value={targetFoodCost}
                  onChange={(e) => setTargetFoodCost(Number(e.target.value))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                        <IconButton size="small" onClick={() => setTargetFoodCost(Math.max(0, targetFoodCost - 1))}>
                          <Minus size={16} variant="Linear"/>
                        </IconButton>
                        <IconButton size="small" onClick={() => setTargetFoodCost(targetFoodCost + 1)}>
                          <Add size={16} variant="Linear"/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Ingredients Table */}
          <Paper elevation={0} sx={{ p: 3, mb: 4, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              <Chart21 size={24} variant="Bulk" color="#F0531C"/>
              <Typography variant="h6" color="primary">
                Ingredients
              </Typography>
            </Box>
            <TableContainer sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Purchase Price ($)</TableCell>
                    <TableCell>Quantity Purchased</TableCell>
                    <TableCell>Quantity Used</TableCell>
                    <TableCell align="right">Cost ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ingredients.map((ingredient, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          placeholder="Enter ingredient name"
                          value={ingredient.name}
                          onChange={(e) => updateIngredient(index, 'name', e.target.value)}
                          variant="standard"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={ingredient.purchasePrice}
                          onChange={(e) => updateIngredient(index, 'purchasePrice', Number(e.target.value))}
                          variant="standard"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <TextField
                            type="number"
                            value={ingredient.quantityPurchased}
                            onChange={(e) => updateIngredient(index, 'quantityPurchased', Number(e.target.value))}
                            variant="standard"
                            sx={{ width: '100px' }}
                          />
                          <FormControl variant="standard" sx={{ minWidth: 80 }}>
                            <Select
                              value={ingredient.quantityPurchasedUnit}
                              onChange={(e) => updateIngredient(index, 'quantityPurchasedUnit', e.target.value)}
                            >
                              <MenuItem value="UNIT">UNIT</MenuItem>
                              <MenuItem value="G">G</MenuItem>
                              <MenuItem value="KG">KG</MenuItem>
                              <MenuItem value="ML">ML</MenuItem>
                              <MenuItem value="L">L</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <TextField
                            type="number"
                            value={ingredient.quantityUsed}
                            onChange={(e) => updateIngredient(index, 'quantityUsed', Number(e.target.value))}
                            variant="standard"
                            sx={{ width: '100px' }}
                          />
                          <FormControl variant="standard" sx={{ minWidth: 80 }}>
                            <Select
                              value={ingredient.quantityUsedUnit}
                              onChange={(e) => updateIngredient(index, 'quantityUsedUnit', e.target.value)}
                            >
                              <MenuItem value="UNIT">UNIT</MenuItem>
                              <MenuItem value="G">G</MenuItem>
                              <MenuItem value="KG">KG</MenuItem>
                              <MenuItem value="ML">ML</MenuItem>
                              <MenuItem value="L">L</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        ${calculateIngredientCost(ingredient).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              startIcon={<Add size={20} variant="Linear"/>}
              onClick={addIngredient}
              variant="outlined"
              color="primary"
            >
              Add Ingredient
            </Button>
          </Paper>

          {/* Comments Section */}
          <Paper elevation={0} sx={{ p: 3, mb: 4, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              <Note1 size={24} variant="Bulk" color="#F0531C"/>
              <Typography variant="h6" color="primary">
                Additional Notes
              </Typography>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder="Enter any additional notes or comments about the recipe"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                
                onClick={calculateResults}
                size="large"
              >
                Calculate
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Trash size={20} variant="Linear"/>}
                onClick={clearAll}
                size="large"
              >
                Clear All
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Columna derecha - Resultados */}
        <Grid item xs={12} md={4}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              borderRadius: 2, 
              border: '1px solid', 
              borderColor: 'divider',
              position: 'sticky',
              top: 24
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4, justifyContent: 'center' }}>
              <DollarCircle size={24} variant="Bulk" color="#F0531C"/>
              <Typography variant="h6" color="primary">
                Results
              </Typography>
            </Box>

            {/* Circular Progress */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  variant="determinate"
                  value={actualFoodCost}
                  size={160}
                  thickness={4}
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    backgroundColor: (theme) => theme.palette.grey[200],
                  }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant="h4" component="div" color="primary">
                    {actualFoodCost > 0 ? `${actualFoodCost.toFixed(0)}%` : '--'}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Food Cost
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Results Cards */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{ bgcolor: 'background.default' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Money size={24} variant="Bulk" color="#F0531C"/>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Actual Food Cost
                        </Typography>
                        <Typography variant="h6">
                          {actualFoodCost > 0 ? `${actualFoodCost.toFixed(2)}%` : '--'}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card variant="outlined" sx={{ bgcolor: 'background.default' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Money size={24} variant="Bulk" color="#F0531C"/>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Expected Profit
                        </Typography>
                        <Typography variant="h6">
                          {expectedProfit > 0 ? `$${expectedProfit.toFixed(2)}` : '--'}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card variant="outlined" sx={{ bgcolor: 'background.default' }}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <DollarCircle size={24} variant="Bulk" color="#F0531C"/>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Expected Selling Price
                        </Typography>
                        <Typography variant="h6">
                          {expectedSellingPrice > 0 ? `$${expectedSellingPrice.toFixed(2)}` : '--'}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    );
};

export default Calculator;