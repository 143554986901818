import { Box, Typography, Button, Paper, List, ListItem, ListItemText, IconButton } from '@mui/material';
import Calendar from 'react-calendar';
import '../orderHistory/calendar.css';
import { More } from 'iconsax-react';  
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Grid, ListItemIcon} from "@mui/material";


const orders = [
  { date: "01", emoji: "📦", description: "Order shipped to Filippo’s Bar" },
  { date: "22", emoji: "🚚", description: "Order shipped to Filippo’s Bar" },
  { date: "28", emoji: "🇪🇸", description: "Order shipped to Filippo’s Bar" },
];
// Estilos personalizados para el calendario
const customStyles = {
  calendarWrapper: {
    backgroundColor: '#F4F6F8', // Fondo claro
    borderRadius: '12px',
    border: "none", // Bordes redondeados
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Sombra ligera
  },
  calendarHeader: {
    display: 'flex',
    marginBottom: "20px",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  calendar: {
    borderRadius: '20px',
    boxShadow: "none",
    fontFamily: "Inter var",
  },
  button: {
    backgroundColor: 'rgba(253, 237, 231, 1)', // Color de fondo del botón
    color: 'rgba(240, 83, 28, 1)',
    borderRadius: '8px',
    padding: '4px 15px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#ffd7bb', // Color de fondo al pasar el mouse
    },
  },
  eventList: {
    marginTop: '20px',
  },
  listItem: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listItemText: {
    fontSize: '14px',
  },
  iconButton: {
    padding: '5px',
  }
};

const events = [
  { date: '01', title: 'Order shipped to Filippo' },
  { date: '22', title: 'Order shipped to Filippo' },
  { date: '28', title: 'Order shipped to Filippo' },
];

const CalendarSection = ({ calendarDate, setCalendarDate }) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
    <Box sx={{ backgroundColor: '#FBFAFE', p: 2, borderRadius: 1, mb: 2, border: "1px solid #E9E5F1" }}>

            {/* Header */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
              <Typography variant="h6" fontWeight="bold">
                Calendar
              </Typography>
              <Button  sx={{backgroundColor: "rgba(253, 237, 231, 1)", color: "rgba(240, 83, 28, 1)", }}>
                View Calendar
              </Button>
            </Box>

            {/* Calendar */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar sx={{ml:"-24px"}} />
            </LocalizationProvider>


            {/* Order List */}
            <List>
              {orders.map((order, index) => (
                <ListItem key={index} divider>
                  <ListItemIcon>
                    <Typography variant="h6">{order.emoji}</Typography>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontWeight="bold" sx={{ mr: 1 }}>
                          {order.date}
                        </Typography>
                        <Typography>{order.description}</Typography>
                      </Box>
                    }
                  />
                  <Typography sx={{ color: "#B0BEC5" }}>...</Typography>
                </ListItem>
              ))}
            </List>

    </Box>
  </Grid>
  );
};

export default CalendarSection;