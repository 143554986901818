import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import {  useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Box
} from '@mui/material';
import { More } from 'iconsax-react';

// third-party

import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

import { PopupTransition } from 'components/@extended/Transitions';
import {
  HeaderSort,
  SortingSelect,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';


import makeData from 'data/react-table';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';




// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'orderId', desc: false };
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setHiddenColumns,
      allColumns,
      visibleColumns,
      rows,
      page,
      gotoPage,
      setPageSize,
      state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
      preGlobalFilteredRows,
      setGlobalFilter,
      setSortBy,
    } = useTable(
      {
        columns,
        data,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 8, hiddenColumns: [], sortBy: [sortBy] }
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect
    );
  
    useEffect(() => {
      if (matchDownSM) {
        setHiddenColumns(['bar', 'date', 'status']);
      } else {
        setHiddenColumns([]);
      }
      // eslint-disable-next-line
    }, [matchDownSM]);
  
    return (
      <>
        {/* Encabezado */}
        <Stack spacing={2}  sx={{ p: 3, backgroundColor:"#F4F6F8"  }}>
          {/* Primera Línea: Título y Botón */}
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight={"600"} fontSize={"18px"} variant="h5">
              Orders
            </Typography>
  
            <Button
              variant="contained"
              onClick={() => alert('View all orders clicked!')}
              sx={{
                bgcolor: '#FDECE5',
                color: 'rgba(255, 102, 0, 1)',
                borderRadius: '8px',
                boxShadow: 'none',
                '&:hover': {
                  bgcolor: '#FFD7BB',
                  color: 'rgba(255, 51, 0, 1)'
                }
              }}
            >
              View All Orders
            </Button>
          </Stack>
  
          {/* Segunda Línea: Filtros */}
          <Stack
            direction={matchDownSM ? 'column' : 'row'}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Filtro Global */}
          <GlobalFilter sx={{backgroundColor:"#FFFFFF"}} 
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
            {/* Selector de Ordenamiento */}
          <SortingSelect  sx={{backgroundColor:"#FFFF !important"}} sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
        </Stack>
      </Stack>
  
        {/* Tabla con Espaciado */}
      <Stack spacing={3}  sx={{ px: 3, pb: 3, backgroundColor:"#F4F6F8" }}>
          <TableRowSelection selected={Object.keys(selectedRowIds).length} />
          <Table {...getTableProps()} sx={{ borderCollapse: 'separate', borderSpacing: '0px' }}>
  <TableHead>
    {headerGroups.map((headerGroup) => (
      <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <TableCell key={column.id} {...column.getHeaderProps([{ className: column.className }])}>
            <HeaderSort column={column} sort />
          </TableCell>
        ))}
        {/* Columna de tres puntos al final */}
        <TableCell align="center">
          {/* Aquí agregamos el ícono de los tres puntos */}
          
        </TableCell>
      </TableRow>
    ))}
  </TableHead>
  
  <TableBody sx={{
    px: 3,
    pb: 3,
    border: '15px solid grey', 
    borderRadius: '15px', 
    boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor:"#FFFF"
  }} {...getTableBodyProps()}>
    {page.map((row, i) => {
      prepareRow(row);
      const rowProps = row.getRowProps();
      
      return (
        <Fragment key={i}>
          <TableRow {...row.getRowProps()} sx={{ cursor: 'pointer' }}>
            {row.cells.map((cell) => (
              <TableCell key={cell.id} {...cell.getCellProps([{ className: cell.column.className }])}>
                {cell.render('Cell')}
              </TableCell>
            ))}
            {/* Columna con los tres puntos */}
            <TableCell align="center">
              <More color="black" sx={{ cursor: "pointer" }} />
            </TableCell>
          </TableRow>
          {row.isExpanded &&
            renderRowSubComponent({
              row,
              rowProps,
              visibleColumns,
              expanded,
            })
          }
        </Fragment>
      );
    })}
    <TableRow>
      <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
        <TablePagination
          gotoPage={gotoPage}
          rows={rows}
          setPageSize={setPageSize}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      </TableCell>
    </TableRow>
  </TableBody>
</Table>
        </Stack>
      </>
    );
  }
  
  ReactTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    getHeaderProps: PropTypes.func,
    handleAdd: PropTypes.func,
    renderRowSubComponent: PropTypes.any
  };
// ==============================|| ORDER - LIST ||============================== //

const OrderHistory = () => {
  const theme = useTheme();
  //const data = useMemo(() => makeData(200), []);
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
        fetch("http://localhost:82/api/order")
          .then(response => response.json())
          .then(data => {
            
            if (Array.isArray(data.data)) {
              console.log("Órdenes obtenidas:", data.data);
              setData(data.data); 
            } else {
              console.error("La respuesta no contiene un array válido de Orders.");
              setData([]); 
            }
          })
          .catch(error => {
            console.error("Error fetching Order:", error);
            setData([]); 
          });
      }, []);

  const handleAdd = () => {
    setAdd(!add);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Order',
        accessor: 'orderId'
      },
      {
        Header: 'Proveedor',
        accessor: 'bar'
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          const formattedDate = new Date(value).toLocaleDateString(); 
          return <span>{formattedDate}</span>;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          const handleClick = () => {
            if (value === "Pending") {
              window.location.href = '/order'; 
            }
          };
        
          return (
            <Chip
              onClick={handleClick}
              sx={{ fontWeight:"700", cursor: value === "Pending" ? 'pointer' : 'default' }}
              color={
                value === "Pending"
                  ? 'info'
                  : value === 'On delivery'
                  ? 'warning'
                  : value === 'Delivered'
                  ? 'success'
                  : 'default'
              }
              label={
                value === 'Pending'
                  ? 'Pending ->'
                  : value === 'On delivery'
                  ? 'On delivery'
                  : value === 'Delivered'
                  ? 'Delivered'
                  : 'Unknown'
              }
              size="small"
              variant="light"
            />
          );
        },
      }
    ],
    [theme]
  );

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <div>
        Order Details for: <strong>{row.original.order}</strong>
        <ul>
          <li>ID: {row.original.id}</li>
          <li>Bar: {row.original.bar}</li>
          <li>Date: {row.original.date}</li>
          <li>Status: {row.original.statusOrder}</li>
        </ul>
      </div>
    ),
    []
  );


  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable columns={columns} data={data} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />
      </ScrollX>
      {/* Add customer dialog */}
      <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        fullWidth
        onClose={handleAdd}
        open={add}
        sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
        aria-describedby="alert-dialog-slide-description"
      >
     
      </Dialog>
    </MainCard>
  );
};

OrderHistory.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  message: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
};

export default OrderHistory;