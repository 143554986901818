import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery } from '@mui/material';
import { TickCircle, Wallet } from 'iconsax-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from "@mui/material";
import useAuth from 'hooks/useAuth';
import { useTheme } from '@mui/material/styles';
import Confetti from "react-confetti";
import { APICall } from 'api';
import { useParams } from 'react-router';

const EventRequest = ({ eventParam }) => {
  const [openReject, setOpenReject] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [event, setEvent] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [consumptionDateTime, setConsumptionDateTime] = useState([]);
  const [realConsumption, setRealConsumption] = useState(''); 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();
  const [fileData, setFileData] = useState([]);
  const [stockWithoutConsumption, setStockWithoutConsumption] = useState([]);
  const pathArray = window.location.pathname.split('/');
  const eventId = pathArray[2]; 
  const [products, setProducts] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!eventParam) return;
  
    setEvent(eventParam);
    if (eventParam.products) {
      console.log("Productos del evento antes del mapeo:", eventParam.products);
      
      // Mapeo de productos
      const mappedProducts = eventParam.products.map((p) => ({
        product: p.productId?.name,
        quantity: p.quantity,
        stockWithoutConsumption: p.stockWithoutConsumption || 0,
      }));
      console.log("Productos mapeados:", mappedProducts);
      setFileData(mappedProducts);
    }
  }, [eventParam]);


  
  
  useEffect(() => {
    APICall.getEventById(eventParam?._id)
      .then((response) => {
        console.log("eventoById",response);
        setEvent(response.data);
        const mappedProducts = response.data.products.map((p) => ({
          product: p.productId.name,
          quantity: p.quantity,
          _id: p.productId._id
        }));
        setFileData(mappedProducts);
        console.log("Productos mapeados 2", mappedProducts);
      })
      .catch((error) => {
        console.error("Error fetching event:", error);
      });
  }, []);


  const handleConsumptionDateChange = (index, value) => {
    const updatedConsumptionDateTime = [...consumptionDateTime];
    updatedConsumptionDateTime[index] = value;
    setConsumptionDateTime(updatedConsumptionDateTime);
  };
  const importEffectiveConsumption = () => {
    setRealConsumption('Cargado');
  };

  const fetchProducts = async () => {
    try {
      const response = await APICall.getProductsByInventoryFile();
      const fetchedProducts = response.data;
  
      const filteredProducts = [];
      const seenNames = new Set();
      fetchedProducts.forEach(product => {
        const baseName = product.name.split(" - ")[0].toLowerCase().trim();
        if (!seenNames.has(baseName)) {
          seenNames.add(baseName);
          filteredProducts.push(product);
        }
      });
      console.log("productossss", fetchedProducts);
      setProducts(filteredProducts);
  
      return filteredProducts;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };
  
  const handleNext = async () => {
    setIsSaving(true);
    const updatedProducts = event.products.map((product, index) => {
      const updatedProduct = {
        ...product,
        stockWithoutConsumption: fileData[index]?.stockWithoutConsumption ?? 0,
        consumptionUploadDateTime: fileData[index]?.consumptionUploadDateTime || null,
      };
  
      console.log("Producto actualizado en event:", updatedProduct);
      return updatedProduct;
    });
  
    const updatedEvent = {
      ...event,
      products: updatedProducts,
    };
  
    try {
    
      const response = await APICall.updateEvent(eventId, updatedEvent);
      console.log("Evento actualizado:", response.data);
  
 
      let updatedInventory = [];
  
      for (const updatedProduct of updatedProducts) {
        console.log("Verificando producto:", updatedProduct);
        
      
        const productId = updatedProduct.productId._id || updatedProduct.productId; 
        console.log("productId extraído:", productId);
  
        if (!productId) {
          console.warn(`productId no válido en producto: ${updatedProduct}`);
          continue;
        }
  

        console.log("Productos disponibles para buscar:", products);
  
        
      const ProductosLista = await fetchProducts(); 
      console.log("Productos lista:", ProductosLista );
  
      setProducts(ProductosLista);  
      
        const product = ProductosLista.find(p => String(p._id) === String(productId)); 
  
        if (!product) {
          console.warn(`Producto no encontrado: ${productId}`);
          continue;
        }
  
       
        const updatedStock = product.stock + updatedProduct.stockWithoutConsumption;
  
        const updatedStockData = {
          ...product,
          stock: updatedStock,  
        };
  
        try {
        
          const updateResponse = await APICall.editProductByUser(productId, updatedStockData);
          console.log("Respuesta de la API para actualizar producto:", updateResponse);
  
       
          if (updateResponse.data && updateResponse.data._id && updateResponse.data.stock === updatedStockData.stock) {
            console.log(`Producto ${productId} actualizado con stock ${updateResponse.data.stock}`);
            updatedInventory.push(updateResponse.data);
          } else {
            console.warn(`Error al actualizar el producto ${productId}. Respuesta inválida:`, updateResponse.data);
          }
        } catch (err) {
          console.error(`Error al actualizar el producto ${productId}:`, err);
        }
      }
  
    
      console.log("Productos actualizados en el inventario:", updatedInventory);
  
     
      setProducts(prev => prev.map(p => updatedInventory.find(up => String(up._id) === String(p._id)) || p));
  
      
      const freshProducts = await fetchProducts(); 
      console.log("Productos después de la actualización:", freshProducts);
  
      setProducts(freshProducts);  
  
      setOpenTable(false);
      window.location.reload();
      handleClose();
    } catch (error) {
      console.error("Error al actualizar el evento:", error);
      alert(`Ocurrió un error al actualizar el evento. Detalles: ${error.message}`);
    }
    finally {
      setIsSaving(false);
    }
  };
  


  const handleConfirmClose = () => setOpenConfirm(false);

  const handleOpenTable = () => {
    setOpenTable(true);
  };

  const handleClose = () => {
    setOpenTable(false);
  };
  useEffect(() => {
    if (!eventParam) return;

      setEvent(eventParam)
  }, [eventParam]);

  const [errorMessages, setErrorMessages] = useState(""); // Estado para manejar el mensaje de error

  const handleStockChange = (index, value) => {
    const updatedFileData = [...fileData];
    const item = updatedFileData[index];
    const newValue = parseInt(value, 10) || "";
  
    // Verificar si el stockWithoutConsumption es mayor que el initial stock (quantity)
    if (newValue > item.quantity) {
      // Establecer el mensaje de error para el producto específico
      setErrorMessages({
        ...errorMessages,
        [item._id]: `You cannot select more than ${item.quantity} units.`,
      });
      return; // No actualizar el valor si es mayor que el stock inicial
    }
  
    // Limpiar el mensaje de error si el valor es válido
    setErrorMessages({
      ...errorMessages,
      [item._id]: "",
    });
  
    // Actualizar los datos del producto
    updatedFileData[index] = {
      ...updatedFileData[index],
      stockWithoutConsumption: newValue,
      consumptionUploadDateTime: value ? new Date().toISOString() : null,
    };
  
    setFileData(updatedFileData);
  };
  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };
  const hasConsumptionDateTime = event.products && event.products.some(product => product.consumptionUploadDateTime);


  if (!event) {
    return (
      <Typography variant="h6" color="textSecondary" sx={{ p: 3 }}>
        Loading event details...
      </Typography>
    );
  }

  return (
    <Box
      open={event}
      sx={{
        backgroundColor: '#F9F6FF',
        padding: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* Event Details */}
      <Typography
        variant="h5"
        fontWeight="700"
        fontSize={isSmallScreen ? '24px' : '32px'}
        sx={{ mb: 1 }}
      >
        {event.name}
      </Typography>

      {/* Event Information */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          justifyContent: 'space-between',
          gap: 4,
        }}
      >
        {/* Event Details */}
        <Box>
  <Typography variant="body1" sx={{ mb: 0, fontWeight: "800", fontSize: "20px" }}>
    Event Details
  </Typography>
  <Typography variant="body1" sx={{ mb: 1 }}>
    Event Date: <span style={{ color: 'black', fontWeight: '600' }}>{formatDate(event.eventDate)}</span>
  </Typography>
  {event.products.some(product => product.consumptionUploadDateTime) && (
    <Typography variant="body1">
      Consumption Upload Date & Time: 
      <span style={{ color: 'black', fontWeight: '600' }}>
        {formatDate(event.products.find(product => product.consumptionUploadDateTime)?.consumptionUploadDateTime)}
      </span>
    </Typography>
  )}
  <Typography variant="body1">
    Location: <span style={{ color: 'black', fontWeight: '600' }}>{event.location}</span>
  </Typography>
  <Typography variant="body1">
    Number of People: <span style={{ color: 'black', fontWeight: '600' }}>{event.numberOfPeople}</span>
  </Typography>

  {!hasConsumptionDateTime && (
      <Button
        sx={{
          color: 'white',
          backgroundColor: '#F0531C',
          marginTop: '20px',
          width: '280px',
          fontSize: '15px',
          letterSpacing: '1px',
          height: '40px',
          '&:hover': { backgroundColor: '#D94318', color: 'white' },
        }}
        onClick={() => { setOpenTable(true); importEffectiveConsumption(); }}
      >
        Import Effective Consumption
      </Button>
    )}
</Box>
      
        <Dialog open={openTable} onClose={handleClose}>
  <DialogTitle sx={{ fontWeight: "700", fontSize: "20px" }}>
    Effective Consumption Upload
  </DialogTitle>
  <DialogContent>
    <Typography sx={{ marginBottom: 2 }}>
      Input data to process the effective consumption data. Ensure the information is accurate before confirming.
    </Typography>
    
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Image</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Product</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Initial Stock</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Stock Without Consumption</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {fileData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <img
                  src={APICall.getProductImage(item.image)} 
                  alt={item.product} 
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    display: "block",
                  }}
                />
              </TableCell>
              <TableCell>{item.product}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>
              <TextField
              type="number"
              value={item.stockWithoutConsumption || ""}
              onChange={(e) => handleStockChange(index, e.target.value)}
              placeholder="Enter stock without consumption"
            />
            {errorMessages[item._id] && (
              <p style={{ color: "red", fontSize: "12px" }}>
                {errorMessages[item._id]}
              </p>
            )}
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </DialogContent>
  <DialogActions>
    <Button 
    sx={{ width: "200px", mb: 2, mr: 35 }} 
    onClick={handleNext} 
    color="primary" 
    variant="contained"
    disabled={isSaving}
  >
    {isSaving ? <CircularProgress size={24} sx={{ color: "#F0531C" }} /> : "Next"}
  </Button>
  </DialogActions>
</Dialog>
        </Box>
    </Box>
  );
};

export default EventRequest;